import { useState, useEffect } from "react";
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/eron moosk.png";
import headerNameImg from "../assets/img/Eron Moosk Name.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import navIcon1 from '../assets/img/x_logo.png';
import navIcon2 from '../assets/img/telegram_logo.png';
import navIcon3 from '../assets/img/dexscreener_logo.png';
import navIcon4 from '../assets/img/solscan_logo.png';
import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";


export const Banner = () => {

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
        <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <img src={headerNameImg} alt="Header Img"/>
                  <div>
                    <p>
                      token address: FThDUZ5y2R5qr5WnUPUN1nvB5CAWGFukLBsECdibFzYY
                    </p>
                  </div>
                  <div className="social-icon">
                    <a href="https://twitter.com/EronMooskonsol"><img src={navIcon1} alt="" /></a>
                    <a href="https://t.me/EronMooskCommunity"><img src={navIcon2} alt="" /></a>
                    <a href="https://dexscreener.com/solana/cb5hdqhdt5ijesf9j73fh86frlzj7uayztpzrag44hb8"><img src={navIcon3} alt="" /></a>
                    <a href="https://solscan.io/token/FThDUZ5y2R5qr5WnUPUN1nvB5CAWGFukLBsECdibFzYY"><img src={navIcon4} alt="" /></a>
                </div>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>


      </Container>
    </section>
  )
}
