import { useState, useEffect } from "react";
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/eron moosk.png";
import admenturs_uf_eron from "../assets/img/admenturs_uf_eron.png";
import aboot_me from "../assets/img/aboot_me2.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import navIcon1 from '../assets/img/nav-icon1 black.svg';
import navIcon2 from '../assets/img/nav-icon2 black.svg';
import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";


export const About = () => {

  return (
    <section className="about" id="aboot">
      
      <Container>
        <h2>
      <img src={aboot_me} alt="Header Img" className="meme_header"/>
    </h2>
    <div>
    <p>Am Eron Moosk. Welcum to mi token. I lek rockets. Weeeeeeee. I also lek biches but avnd ad sex n a wile.</p>
    </div>
      </Container>
    </section>
  )
}
