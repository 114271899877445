import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
/*
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
*/

import projImg1 from "../assets/img/spliff_white.png";
import projImg2 from "../assets/img/barcode and elon_white.png";
import projImg3 from "../assets/img/jackma and elon_white.png";
import projImg4 from "../assets/img/elon and zuck_white.png";
import projImg5 from "../assets/img/let that sink in_white.png";
import projImg6 from "../assets/img/bub and elon_white.png";
import admenturs_uf_eron from "../assets/img/admenturs_uf_eron.png";


import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Memes = () => {

  const memes = [
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg1,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg2,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg3,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg4,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg5,
    },
    {
      title: "We are severely depressed. Please buy $SAD",
      description: "",
      imgUrl: projImg6,
    }
  ];

  return (
    <section className="meme" id="admenturs">
      
      <Container>
        <h2>
      <img src={admenturs_uf_eron} alt="Header Img" className="meme_header"/>

        </h2>
        
        
      <img src={projImg1} alt="Header Img" className="meme_img"/>
      
      <img src={projImg2} alt="Header Img" className="meme_img"/>
      
      <img src={projImg3} alt="Header Img" className="meme_img"/>
      
      <img src={projImg4} alt="Header Img" className="meme_img"/>
      
      <img src={projImg5} alt="Header Img" className="meme_img"/>
      
      <img src={projImg6} alt="Header Img" className="meme_img"/>
      </Container>
    </section>
  )
}
