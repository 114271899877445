
import { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import rodmap from "../assets/img/rodmap.png";
import flip from "../assets/img/flip.mp4";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import ListGroup from 'react-bootstrap/ListGroup';

/*

      <li>Contract audits show no warnings</li>
      */

export const Roadmap = () => {


  return (
    <section className="roadmap" id="rodmap">
    <Container>
        <h2>
      <img src={rodmap} alt="Header Img" className="meme_header"/>
    </h2>

    <div class="remoteVideo-container">
    <video autoPlay muted loop className="remoteVideo">
        <source src={flip}/>
      </video>
      </div>
      
      </Container>
    </section>

  )
}
