
import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import teconamics from "../assets/img/teconamics.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import ListGroup from 'react-bootstrap/ListGroup';

/*

      <li>Contract audits show no warnings</li>
      */

export const Tokenomics = () => {

  return (

    <section className="about" id="teconamics">
    <Container>
        <h2>
      <img src={teconamics} alt="Header Img" className="meme_header"/>
    </h2>
    <div>
    <p>Numba of tokens is 18,446,744,073.709551615.<br/>LP burmed. Omership remoached.</p>

    </div>
      </Container>
    </section>
    )
}
